import React from 'react';

export const AppContext = React.createContext();

const clickId = (id) => () => {
  const el = document.getElementById(id);
  if (!el) {
    console.error(`AppContext.clickId: Element not found ${JSON.stringify(id)}`);
    return;
  }
  el.click();
};

/**
 * @typedef {Object}      View
 * @property {String}     url - Unique View identifier
 */

/**
 * @typedef {Object}      MapProps
 * @property {String}     mapId
 * @property {Object}     center - { lat, lng }
 * @property {Boolean}    marker
 * @property {String}     markerTitle
 */

/**
 * @typedef {Object}      Location
 * @property {String}     address
 * @property {String}     city
 * @property {String}     state
 * @property {String}     stateLong - Full state name
 * @property {String}     zip
 * @property {String}     phone
 * @property {String}     suite
 */

/**
 * @typedef {Object}            Hours
 * @property {String}           dayText
 * @property {String}           dayHours
 * @property {Object}           dayHoursMap - Map of Day of the Week -> Hours
 * @property {Array<String>}    days
 * @property {Number}           dayState
 * @property {Function}         setDayState
 * @property {Function}         resetDayState
 * @property {Function}         nextHours
 */

/**
 * @typedef {Object}      AppState
 * @property {Function}   onEmailClick
 * @property {Function}   onInstaClick
 * @property {Function}   onBookClick
 * @property {Function}   onDirectionsClick
 * @property {View}       view
 * @property {Function}   setView - fn(View) Sets the active view
 * @property {Function}   setHomeView - fn() Clears the active view
 * @property {MapProps}   mapProps
 * @property {String}     googlePlacesApiKey
 * @property {String}     googlePlaceId
 * @property {Location}   location
 * @property {Hours}      hours
 */

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const hours = {
  Saturday: '12:00 - 6:00',
};

/**
 * @returns {AppState}
 */
function useState() {
  const onEmailClick = clickId('email-link');
  const onInstaClick = clickId('insta-link');
  const onBookClick = clickId('book-link');
  const onDirectionsClick = clickId('directions-link');
  const [view, setViewState] = React.useState();

  const [dayState, setDayState] = React.useState(new Date().getDay());
  function resetDayState() {
    setDayState(new Date().getDay());
  }
  function nextHours() {
    let next = dayState + 1;
    if (next > days.length - 1) next = 0;
    setDayState(next);
  }
  const dayText = days[dayState];
  const dayHours = hours[dayText] || 'Closed';
  const hoursState = {
    dayText,
    dayHours,
    dayState,
    days,
    dayHoursMap: days.reduce((acc, d) => {
      acc[d] = hours[d] || 'Closed';
      return acc;
    }, {}),
    setDayState,
    resetDayState,
    nextHours,
  };

  function setView(newView) {
    if (!newView || typeof newView !== 'object') {
      console.error('AppState.setView: newView is not defined.');
      return;
    }
    if (!newView.url) {
      console.error('AppState.setView: newView.url is not defined.');
      return;
    }

    setViewState(newView);
  }
  function setHomeView() {
    setViewState();
  }

  const mapProps = {
    mapId: 'TMCDSkin',
    center: { lat: 27.4455658, lng: -82.5872362 },
    marker: true,
    markerTitle: 'The Michael Dates Classic',
  };
  const googlePlacesApiKey = '';
  const googlePlaceId = '';
  const location = {
    address: 'TBD',
    // suite: 'Suite F',
    city: 'Bradenton',
    state: 'FL',
    stateLong: 'Florida',
    zip: '34207',
    phone: 'TBD',
  };

  return {
    onEmailClick,
    onInstaClick,
    onBookClick,
    onDirectionsClick,
    view,
    setView,
    setHomeView,
    mapProps,
    googlePlacesApiKey,
    googlePlaceId,
    location,
    hours: hoursState,
  };
}

function ClickLinks() {
  return (
    <React.Fragment>
      <a
        id="email-link"
        href="mailto:dates-classic@proton.me"
        style={{ display: 'none' }}
      >
        Send Email
      </a>
      {/* <a
        id="insta-link"
        href="https://www.instagram.com/"
        style={{ display: 'none' }}
        target="_blank"
        rel="noreferrer"
      >
        View Instagram
      </a> */}
      {/* <a
        id="book-link"
        href="https://squareup.com/"
        style={{ display: 'none' }}
        target="_blank"
        rel="noreferrer"
      >
        Book with me
      </a> */}
      {/* <a
        id="directions-link"
        // eslint-disable-next-line
        href="https://www.google.com/maps/dir/"
        style={{ display: 'none' }}
        target="_blank"
        rel="noreferrer"
      >
        Directions
      </a> */}
    </React.Fragment>
  );
}

export function AppContextProvider({ children }) {
  const state = useState();
  return (
    <AppContext.Provider value={state}>
      <ClickLinks />
      {children}
    </AppContext.Provider>
  );
}

/**
 * @returns {AppState}
 */
export function useAppContext() {
  const state = React.useContext(AppContext);
  return state;
}
