import React from 'react';
import {
  CssBaseline,
  // Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import classnames from 'classnames';

import themes from './themes';
import {
  AppContextProvider,
  AppContext,
  // useAppContext,
} from './context/AppContext';
// import Button from './components/Button';
// import ImageUnderlay from './components/ImageUnderlay';
import Header from './components/Header';
// import VideoOverlay from './components/VideoOverlay/VideoOverlay';

// import GoogleReviews from './components/GoogleReviews/GoogleReviews';

const useStyles = makeStyles((theme) => ({
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  header1: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '50px!important',
    },
  },
  header2: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px!important',
    },
  },
  textSecondaryContrast: { color: theme.palette.secondary.contrastText },
  mobileVideo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

// const frontVidSrc = 'https://storage.googleapis.com/BUCKET/FOLDER/vid.mp4';
// const frontVidType = 'video/mp4';
function HomeView() {
  // const classes = useStyles();
  return (
    <React.Fragment>
      {/* <NavBar /> */}
      {/* <VideoOverlay src={frontVidSrc} type={frontVidType} /> */}
      {/* <video className={classes.mobileVideo} muted controls autoPlay>
        <source src={frontVidSrc} type={frontVidType} />
        Your browser does not support the video tag.
      </video> */}
      {/* <GoogleReviews cyclePeriod={7000} minRating={4} /> */}
      The Michael Dates Classic
      <img src="https://themichaeldatesclassic.com/content/dates1.jpg" alt="Dates-1" />
      <img src="https://themichaeldatesclassic.com/content/dates2.jpg" alt="Dates-1" />
      <img src="https://themichaeldatesclassic.com/content/dates3.jpg" alt="Dates-1" />
    </React.Fragment>
  );
}

const viewComponentMap = {
  // '/app/about': AboutMe,
  // '/app/contact': ContactMe,
  // '/app/services': Services,
  // '/app/gallery': Gallery,
};

function View() {
  const { view } = React.useContext(AppContext);
  const { url: viewUrl } = view || {};
  let viewCompKey = (viewUrl || '').split('/').filter(x => !!x).slice(0, 2).join('/');
  viewCompKey = `/${viewCompKey}`;
  const showHome = !viewComponentMap[viewCompKey];
  return (
    <>
      <div style={{ display: showHome ? '' : 'none' }}>
        <HomeView />
      </div>
      {Object.keys(viewComponentMap).map(key => {
        const Comp = viewComponentMap[key];
        return (
          <div key={key} style={{ display: viewCompKey === key ? '' : 'none' }}>
            <Comp />
          </div>
        );
      })}
      {/* <div style={{ display: showHome ? 'none' : '' }}>
        <Footer
          hideMap={viewUrl && viewUrl.includes('contact')}
        />
      </div> */}
    </>
  );
}

function Layout() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Header />
      <div className={classes.fakeToolbar} />
      <View />
    </React.Fragment>
  );
}

function App() {
  return (
    <ThemeProvider theme={themes.default}>
      <CssBaseline enableColorScheme />
      <AppContextProvider>
        <Layout />
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
