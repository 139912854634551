import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import EmailIcon from '@mui/icons-material/Email';
import InstaIcon from '@mui/icons-material/Instagram';
import DirectionsIcon from '@mui/icons-material/Directions';

import { useAppContext } from '../../context/AppContext';
import Button from '../Button';

export default function Header() {
  const { onEmailClick, onInstaClick, onDirectionsClick, onRegisterClick } = useAppContext();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#abb8c3', color: 'black' }}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <Button
              sharp
              color="primary"
              variant="contained"
              onClick={onRegisterClick}
            >
              Register
            </Button>
          </div>
          <IconButton onClick={onInstaClick}>
            <InstaIcon />
          </IconButton>
          <IconButton onClick={onEmailClick}>
            <EmailIcon />
          </IconButton>
          <IconButton onClick={onDirectionsClick}>
            <DirectionsIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
