import { Button as MuiButton } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import { AppContext } from '../context/AppContext';

const useStyles = makeStyles((theme) => ({
  sharp: {
    borderRadius: '0!important',
  },
  pad2: {
    padding: `${theme.spacing(2)}!important`,
    paddingLeft: `${theme.spacing(4)}!important`,
    paddingRight: `${theme.spacing(4)}!important`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0.5)}!important`,
      paddingLeft: `${theme.spacing(1)}!important`,
      paddingRight: `${theme.spacing(1)}!important`,
    },
  },
  darkPrimary: {
    color: `${theme.palette.primary.dark}!important`,
  },
  darkSecondary: {
    color: `${theme.palette.secondary.dark}!important`,
  },
}));

export default function Button({ sharp, children, pad, classes: classesProp = {}, dark, ...props }) {
  const classes = useStyles();
  const { color, variant } = props;
  return (
    <MuiButton
      {...props}
      classes={{
        root: classnames(classesProp.root, {
          [classes.sharp]: sharp === true,
          [classes.pad2]: pad === 2,
          [classes.darkPrimary]: dark && [undefined, 'outlined', 'dashed'].includes(variant) && color === 'primary',
          [classes.darkSecondary]: dark && [undefined, 'outlined', 'dashed'].includes(variant) && color === 'secondary',
        }),
      }}
    >
      {children}
    </MuiButton>
  );
}

export function ViewButton({ view, ...props }) {
  const { setView } = React.useContext(AppContext);
  const onClick = React.useCallback(() => {
    setView(view);
  }, [view, setView]);

  return (
    <Button
      {...props}
      onClick={onClick}
    />
  );
}
